<template>
  <li class="notice-item" :class="data.status" @click=removeItem>
    <div class="container">
      <div class="notice-icon">
        <img :src="data.icon || '/img/icon-checked.svg'" />
      </div>
      <div class="notice-body">{{ message }}</div>
    </div>
  </li>
</template>

<script>
export default {
  props: [ "data" ],
  data(){
    return {
      message: null
    };
  },
  created(){
    if( typeof this.data.message == "string" ){
      this.message = this.$__t( this.data.message );
    }else{
      if( this.data.message.data && this.data.message.data.error ){
        this.message = this.$__t( this.data.message.data.error );
      }else if( this.data.message.error_msg ){
        this.message = this.$__t( this.data.message.error_msg );
      }else if( this.data.message.details ){
        this.message = this.$__t( this.data.message.details );
      }else if( !this.data.message ){
        this.message = "Unknown Error";
      };
    };
    if( !this.message && typeof this.data.message == "object" ){
      this.message = this.data.message.toString();
    };
  },
  mounted(){
    if( this.data.duration ){
      if( this.data.duration > 0 ){
        setTimeout( this.removeItem, this.data.duration );
      }else{

      };
    }else{
      setTimeout( this.removeItem, 1200 );
    };
  },
  methods: {
    removeItem(){
      this.$store.commit( "notice/REMOVE_ITEM", this.data );
    }
  }
}
</script>
