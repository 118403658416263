<template>
  <li class="dialog-item">
    <div class="container">
      <div class="dialog-body">{{ data.message }}</div>
      <div class="dialog-footer">
        <ui-text-button v-if="data.false" size="small" color="black" @click="callback('false')"><button type="button">{{ data.false.label }}</button></ui-text-button>
        <ui-text-button v-if="data.true" size="small" color="primary" @click="callback('true')"><button type="button">{{ data.true.label }}</button></ui-text-button>
        <ui-text-button v-if="data.ok" size="small" color="primary" @click="callback('ok')"><button type="button">{{ data.ok.label }}</button></ui-text-button>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: [ "data" ],
  mounted(){
    this.$watch( "$route", ( a, b ) => {
      this.removeItem();
    });
  },
  methods: {
    removeItem(){
      this.$store.commit( "dialog/REMOVE_ITEM", this.data );
    },
    callback( response ){
      this.data[ response ].callback();
      this.removeItem();
    }
  }
}
</script>
