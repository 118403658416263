<template>
  <div class="modal-container">
    <transition-group tag="ul" name="modal">
      <modal-item v-for="( item, i ) in items" :key="i+'-'+item.status" :data="item"></modal-item>
    </transition-group>
  </div>
</template>

<script>
import ModalItem from "./Item";
export default {
  components: {
    ModalItem
  },
  computed: {
    items(){
      return this.$store.getters[ "modal/GET_ITEMS" ];
    }
  }
}
</script>
