<template>
  <div class="alert-container">
    <alert-item v-for="(item, i) in items" :key="i + '-' + item.status" :data="item"></alert-item>
  </div>
</template>

<script>
import AlertItem from './Item';
export default {
  components: {
    AlertItem,
  },
  computed: {
    items() {
      return this.$store.getters['alert/GET_ITEMS'];
    },
  },
};
</script>
