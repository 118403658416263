<template>
  <div ref="screen" id="site-loading">
    <transition name="fade" mode="out-in">
      <div v-if="status" class="container" :class="{ covered: isCovered }">
        <i class="fa fa-spinner fa-pulse"></i>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    status() {
      return this.$store.getters["loading/GET_STATUS"];
    },

    isCovered() {
      return this.$store.getters["loading/GET_COVER"];
    },

    imgSrc() {
      const source = require("@/assets/gif/lugstay-loading.gif");

      return source;
    },
  },
};
</script>
