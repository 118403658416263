<template>
  <div class="notice-container">
    <transition-group tag="ul" name="notice">
      <notice-item v-for="( item, i ) in items" :key="i+'-'+item.status" :data="item"></notice-item>
    </transition-group>
  </div>
</template>

<script>
import noticeItem from "./Item";
export default {
  components: {
    noticeItem
  },
  computed: {
    items(){
      return this.$store.getters[ "notice/GET_ITEMS" ];
    }
  }
}
</script>
