<template>
  <div :class="'badge '+type" :style="{'background-color': bgColor, 'border-radius': type == 'circle' ? radius ? radius : '1rem' : null}"><div><slot/></div></div>
</template>

<script>
export default {
  props: {
    type :{
      type: String,
      default: "square"
    },
    bgColor:{
      type: String,
      default: "#0091ff"
    },
    radius: {
      type: String,
      default: null
    }
  }
}
</script>
