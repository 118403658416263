<template>
  <div id="site-wrapper">
    <!-- <language-form v-if="!wni.isNative && !isSetLanguage && $route.name != 'Stores'" /> -->

    <div v-if="wni.isNative && $store.state.config.mode !== 'production'" class="debug-header">
      <div class="debug-header__text">{{ $store.state.config.mode }} {{ $store.state.config.version }}</div>
    </div>

    <!-- <site-event v-if="$route.name === 'Home'" /> -->

    <!-- <site-header
      :type="
        $store.state.config.isTouchDevice
          ? $route.params.headerType || ($route.meta && $route.meta.headerType ? $route.meta.headerType : 'default')
          : 'pc-header'
      "
      :title="$route.meta && $route.meta.title ? $route.meta.title : ''"
      v-if="headerVisible"
    /> -->

    <div
      id="site-container"
      :class="{
        'is-mobile': isTouchDevice,
        'hide-header': !headerVisible,
        'include-tab': $route.meta && $route.meta.headerType === 'iez',
        'more-depth': $route.meta.headerType === 'more-depth' || $route.params.headerType === 'more-depth'
      }"
    >
      <router-view :key="$router.currentRoute.fullPath" />
    </div>

    <!-- <div
      id="site-sidebar-toggler"
      v-if="sidebarTogglerVisible"
      @click="toggleSidebar"
      :class="{
        on: $route.name === 'Stores',
        payment: $route.path.indexOf('/reserve/') > 0
      }"
    >
      <i class="fa fa-bars"></i>
      <badge v-if="redAlarm" type="circle" bg-color="red" class="red-alarm"></badge>
    </div> -->

    <!-- <transition name="sidebar">
      <site-sidebar v-if="sidebarOpened" @close="closeSidebar" />
    </transition> -->

    <site-loading />
    <site-modal />
    <site-dialog />
    <site-alert />
    <site-toast />
    <site-notice />
    <qr-code-scanner />

    <site-footer v-if="footerVisible" />
  </div>
</template>

<script>
import SiteEvent from '@/components/outline/v2/Events.vue';
// import SiteHeader from '@/components/outline/Header';
import SiteFooter from '@/components/outline/Footer';
// import SiteSidebar from '@/components/outline/Sidebar';
import SiteModal from '@/components/modules/Modal/Body.vue';
import SiteDialog from '@/components/modules/Dialog/Body.vue';
import SiteAlert from '@/components/modules/Alert/Body.vue';
import SiteToast from '@/components/modules/Toast/Body.vue';
import SiteNotice from '@/components/modules/Notice/Body.vue';
import SiteLoading from '@/components/modules/Loading/Body.vue';
import Badge from '@/components/modules/Badge/Badge';
import LanguageForm from '@/components/includes/LanguageForm';
import QrCodeScanner from '@/components/modules/QrCodeScanner/QrCodeScanner.vue';

export default {
  name: 'Layout',

  components: {
    SiteEvent,
    // SiteHeader,
    SiteFooter,
    // SiteSidebar,
    SiteModal,
    SiteDialog,
    SiteAlert,
    SiteToast,
    SiteNotice,
    SiteLoading,
    LanguageForm,
    Badge,
    QrCodeScanner
  },

  data() {
    return {
      sidebarOpened: false,
      clipboard: null,
      isSetLanguage: localStorage.getItem('lugstay_language'),
      eventSentByScrollDepth: {
        30: false,
        50: false,
        70: false,
        90: false
      }
    };
  },

  watch: {
    $route: {
      async handler(to, from) {
        this.setPathChanges();

        if (!!to.meta?.hideChannelTalk || to.params?.headerType === 'more-depth') {
          this.$channelTalk.hide();
        } else this.$channelTalk.show();

        Object.keys(this.eventSentByScrollDepth).forEach((res) => {
          this.eventSentByScrollDepth[res] = false;
        });

        if (to && from && to.name !== from.name) {
          if (!this.isSetLanguage) {
            localStorage.setItem('lugstay_language', this.$store.state.lang);
            this.isSetLanguage = localStorage.getItem('lugstay_language');
          }
        }
      },
      deep: true
    },
    sidebarOpened() {
      if (this.sidebarOpened === true) {
        this.$channelTalk.hide();
      } else {
        if (this.$route.meta.hideChannelTalk !== true) {
          this.$channelTalk.show();
        }
      }
    }
  },

  async created() {
    if (this.$route.params.promotion_id) {
      var content = this.$route.params.promotion_id;
      setTimeout(() => {
        this.$store.commit('modal/ADD_ITEM', {
          component: 'Promotion',
          size: 'big',
          payload: { content }
        });
      }, 0);
    }

    await this.fetchData();

    window.addEventListener('scroll', this.onPageScroll);
  },

  mounted() {
    this.setVhSize();
    window.addEventListener('resize', this.setVhSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setVhSize);
  },

  computed: {
    clickedFrom() {
      if (this.$route.name === 'Home') return 'home';
      else if (this.$route.name === 'Stores') return 'stores';
      else if (this.$route.name === 'StoreSingle') return 'shop';
      else return null;
    },

    isAuth() {
      return this.$store.state.auth.gid;
    },

    isAuthHost() {
      return this.$store.state.auth.host_id;
    },
    
    isTouchDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WNEmulator|WNInterface/i.test(navigator.userAgent);
    },

    campaignCode() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'campaignCode' });
    },

    promotion() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'promotion' });
    },

    tickets() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'MyTickets_available'
      });
    },

    ticketsForHost() {
      return this.$store.getters['tickets/GET_ITEMS_BY_LIST']({
        list: 'HostTickets_available'
      });
    },

    notices() {
      return this.$store.getters['resourceNotices/GET_ITEMS_BY_LIST']({
        list: 'Notices'
      });
    },

    unreadNotices() {
      const date_now = this.$moment();
      const newNoticesInAWeek = this.notices.filter((o) => this.$moment.duration(date_now.diff(this.$moment(o.created_at))).asDays() <= 7);

      const noticesViewed = this.getNoticesViewed();

      if (noticesViewed.length === 0) return newNoticesInAWeek;

      const readNotices = newNoticesInAWeek.filter((o) => noticesViewed.indexOf(o.post_id.toString()) > -1);

      this.$store.commit('resourceNotices/SET_LIST_ITEMS', {
        list: 'lugstay_notices_viewed',
        data: readNotices
      });

      return newNoticesInAWeek.filter((o) => noticesViewed.indexOf(o.post_id.toString()) < 0);
    },

    coupons() {
      let coupons = this.$store.getters['resourceCoupons/GET_ITEMS_BY_LIST']({
        list: 'MyCoupons'
      });

      if (coupons && coupons.length > 0) {
        coupons = coupons.filter((e) => !e.used && !e.expired);
      }

      return coupons;
    },

    redAlarm() {
      if ((this.coupons && this.coupons.length) || (this.unreadNotices && this.unreadNotices.length) || (this.tickets && this.tickets.length)) {
        return true;
      }

      return false;
    },

    headerVisible() {
      if (this.$route.meta.nativeLike && this.wni.isNative) {
        return false;
      }

      if (this.$route.meta.headerType === 'none') {
        return false;
      }

      return this.$route.meta && this.$route.meta.hideHeader !== true;
    },

    footerVisible() {
      if (this.wni.isNative) return false;

      if (this.$route.meta.footerType === 'none') {
        return false;
      }

      if (this.$route.meta.headerType === 'more-depth' || this.$route.params.headerType === 'more-depth') {
        return false;
      }

      return this.$route.meta && this.$route.meta.hideFooter !== true;
    },

    sidebarTogglerVisible() {
      if (!this.$store.state.config.isTouchDevice) {
        return false;
      }

      if (this.wni.isNative) {
        return false;
      }

      if (this.$route.meta.headerType === 'more-depth' || this.$route.params.headerType === 'more-depth') {
        return false;
      }

      return this.$route.meta && this.$route.meta.hideSidebar !== true;
    },

    mapVisible() {
      return this.$store.state.mapVisible || false;
    }
  },

  methods: {
    toggleSidebar() {
      // console.log('togglesidebar');
      // console.log('this.sidebar', this.sidebarOpened);
      this.sidebarOpened = !this.sidebarOpened;
      if (this.clickedFrom) this.$analytics.tracking.call(this, this.clickedFrom, 'click', this.clickedFrom + '_sidebar');
    },

    closeSidebar() {
      this.sidebarOpened = false;
    },

    setPathChanges(to, from) {
      if (from && from.path === to.path) {
        this.$store.commit('path/SET_FALSE');
      } else {
        this.$store.commit('path/SET_TRUE');
      }
    },

    async fetchCoupons() {
      let options = {
        list: 'MyCoupons',
        query: {
          page: 1,
          size: 50,
          group: 'available'
        },
        clear: true
      };

      try {
        await this.$store.dispatch('resourceCoupons/getList', options);
      } catch (e) {
        this.$log.error(e);
      }
    },

    async fetchPasses() {
      let options = {
        list: 'MyPasses',
        query: {
          page: 1,
          size: 10,
          group: 'available',
          type: 'ALL'
        }
      };

      try {
        await this.$store.dispatch('resourceProducts/getMyList', options);
      } catch (e) {
        this.$log.error(e);
      }
    },

    async fetchTickets(options = {}) {
      try {
        this.$store.commit('tickets/CLEAR_ITEMS');
        this.$store.commit('tickets/CLEAR_ALL_LIST');
        //clear all tickets

        const TICKETS_ALL = this.$store.dispatch('tickets/getList', {
          list: 'MyTickets_all',
          filter: 'all',
          query: { page: 1, size: 10 }
        });

        const TICKETS_AVAILABLE = this.$store.dispatch('tickets/getList', {
          list: 'MyTickets_available',
          filter: 'available',
          query: { page: 1, size: 10 }
        });

        return await Promise.all([TICKETS_ALL, TICKETS_AVAILABLE]);
      } catch (e) {
        //catch
      }
    },

    // async fetchNotices() {
    //   let options = {
    //     list: 'Notices',
    //     query: {
    //       page: 1,
    //       size: 10
    //     }
    //   };

    //   try {
    //     await this.$store.dispatch('resourceNotices/getList', options);
    //   } catch (e) {
    //     this.$log.error(e);
    //   }
    // },

    async fetchTicketsForHost() {
      let options = {
        list: 'HostTickets_available',
        query: {
          page: 1,
          size: 5
        },
        filter: 'available'
      };

      try {
        await this.$store.dispatch('tickets/getListForHost', options);
      } catch (e) {
        this.$log.error(e);
      }
    },

    async fetchData() {
      let promises = [];
      // promises.push(this.fetchNotices());

      if (this.isAuth) {
        promises.push(this.fetchCoupons(), this.fetchTickets(), this.fetchPasses());
      }

      // if (this.isAuthHost) {
      //   promises.push(this.fetchTicketsForHost());
      // }

      return Promise.all(promises);
    },

    getNoticesViewed() {
      let noticesViewed = localStorage.getItem('lugstay_notices_viewed');

      if (!noticesViewed) return [];

      noticesViewed = noticesViewed.split(',');

      return noticesViewed;
    },

    onPageScroll(e) {
      setTimeout(() => {
        const winHeight = $(window).height();
        const docHeight = $(document).height();
        const scrollTop = $(window).scrollTop();
        const trackLength = docHeight - winHeight;
        const pctScrolled = Math.floor((scrollTop / trackLength) * 100);

        if (30 <= pctScrolled && pctScrolled < 50) {
          if (this.eventSentByScrollDepth[30] === true) return;
          this.$analytics.logTrack('trackCustom', 'Scroll', {
            'Scroll Depth': 30
          });
          this.eventSentByScrollDepth[30] = true;
        } else if (50 <= pctScrolled && pctScrolled < 70) {
          if (this.eventSentByScrollDepth[50] === true) return;
          this.$analytics.logTrack('trackCustom', 'Scroll', {
            'Scroll Depth': 50
          });
          this.eventSentByScrollDepth[50] = true;
        } else if (70 <= pctScrolled && pctScrolled < 90) {
          if (this.eventSentByScrollDepth[70] === true) return;
          this.$analytics.logTrack('trackCustom', 'Scroll', {
            'Scroll Depth': 70
          });
          this.eventSentByScrollDepth[70] = true;
        } else if (90 <= pctScrolled) {
          if (this.eventSentByScrollDepth[90] === true) return;
          this.$analytics.logTrack('trackCustom', 'Scroll', {
            'Scroll Depth': 90
          });
          this.eventSentByScrollDepth[90] = true;
        }
      }, 0);
    },

    setVhSize() {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
  }

  // zIndex Level
  // Header: 1000
  // Fixed Element: 1200
  // Sidebar: 1300
  // Process Modal: 1400;
  // Modal : 1500
  // Dialog : 1600
  // Alert : 1700

  // Modal Size
  // $site-width-h
};
</script>
