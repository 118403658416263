<template>
  <transition name="slide-down">
    <template v-if="!wni.isNative">
      <div v-if="isOpened" class="qr-code-scanner">
        <div class="qr-code-scanner__container">
          <h1 class="qr-code-scanner__header">
            <button type="button" class="header-btn btn-close" v-button @click="closeScanner">
              <img src="/img/icon/close.svg" />
            </button>
            <span class="header-text">QR코드 스캔</span>
          </h1>
          <div
            class="qr-code-scanner__frame"
            @click.stop=""
            :class="{
              processing: scannerProcessing,
              disable: scannerMode === 'disable'
            }"
          >
            <canvas class="qr-code-scanner__canvas" ref="previewCanvas" :class="{ 'is-camera': scannerIsTouchDevice }"></canvas>
            <video class="qr-code-scanner__video" ref="previewVideo" muted autoplay playsinline></video>
            <div v-if="loading" class="qr-code-scanner__loading">
              <lottie :options="lottieOptions" :height="100" :width="100"></lottie>
            </div>
            <div v-else class="qr-code-scanner__guide" :class="{ 'qr-code-scanner__guide--detected': detected }">
              <div class="guide-item guide-top-left"></div>
              <div class="guide-item guide-top-right"></div>
              <div class="guide-item guide-bottom-right"></div>
              <div class="guide-item guide-bottom-left"></div>
              <p class="guide-text">
                {{ $__t('여기에 QR코드를 스캔하세요.') }}
              </p>
            </div>
          </div>
          <p class="qr-code-scanner__explanatory">상점의 QR코드를 인식시켜 주세요.</p>
        </div>
      </div>
    </template>
  </transition>
</template>

<script>
import jsQR from 'jsqr';
import Lottie from 'vue-lottie';
import LottieLoading from '@/assets/lottie/loading-primary.json';

export default {
  name: 'QrCodeScanner',
  data() {
    return {
      scannerInitiated: false,
      scannerMode: this.wni.isNative ? "native" : "web",
      scannerCanvas: null,
      scannerCanvasContext: null,
      scannerVideo: null,
      scannerAvailable: false,
      scannerProcessing: false,
      scannerDrawer: null,
      scannerIsTouchDevice: this.$store.state.config.isTouchDevice,
      detected: true,

      lottieOptions: {
        animationData: LottieLoading
      },
      loading: false
    };
  },

  computed: {
    isOpened() {
      return this.$store.getters['qrCodeScanner/IS_OPENED'];
    },
    isNative() {
      return this.wni.isNative;
    }
  },

  watch: {
    isOpened(opened) {
      // console.log('#QrCodeScanner opened', { opened }, this.scannerMode);

      if (this.wni.isNative) {
        
      }
      else {
        if (opened === true) {
          this.initScanner();
        } else if (opened === false) {
          this.stopScanner();
        }
      }
    }
  },

  mounted() {
    // console.log('#QrCodeScanner mounted', { });

    this.setScannerMode(this.wni.isNative ? 'native' : 'web');
  },

  beforeDestroy() {
    // console.log('#QrCodeScanner beforeDestroy', { });
    if (this.scannerMode === 'native') {
      this.stopScanner();
    }
    this.loading = false;
  },

  methods: {
    closeScanner() {
      // console.log('#QrCodeScanner closeScanner', { });

      this.$store.dispatch('qrCodeScanner/close', {});
    },

    setScannerMode(mode) {
      // console.log('#QrCodeScanner setScannerMode', { mode });

      this.scannerMode = mode;
    },

    openAppSetting() {
      // console.log('#QrCodeScanner openAppSetting', {  });

      this.wni.execute('wnOpenAppSetting', { type: 'general' });
    },

    initScanner() {
      console.log('#QrCodeScanner initScanner', {  });

      try {
        this.loading = true;
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'environment' } })
          .then((res) => {
            this.scannerInitiated = true;
            this.setScannerMode('web');

            setTimeout(() => {
              this.scannerCanvas = this.$refs.previewCanvas;
              if (!this.scannerCanvas) return;
              this.scannerCanvasContext = this.scannerCanvas.getContext('2d');
              this.scannerVideo = this.$refs.previewVideo;
              this.scannerIsTouchDevice = this.$store.state.config.isTouchDevice;

              this.scannerVideo.srcObject = res;
              this.scannerUsable = true;
              this.startScanner();
              this.loading = false;
            }, 0);
          })
          .catch((err) => {
            console.error(err);

            this.stopScanner();
            this.$store.commit('alert/ADD_ITEM', {
              message: err.toString(),
              status: 'error'
            });
            this.scannerUsable = false;
            this.setScannerMode('disable');
            this.loading = false;
          });
      } catch (err) {
        this.stopScanner();
        this.$store.commit('alert/ADD_ITEM', {
          message: err.toString(),
          status: 'error'
        });
        this.scannerUsable = false;
        this.setScannerMode('disable');
        this.loading = false;
      }
    },

    startScanner(timing) {
      console.log('#QrCodeScanner startScanner', {  });

      if (this.scannerUsable) {
        if (timing) {
          setTimeout(() => {
            clearInterval(this.scannerDrawer);
            if (this.scannerVideo) {
              this.scannerVideo.play();
            }
            this.scannerDrawer = setInterval(this.captureVideoToCanvas, 1000 / 24);
            this.scannerProcessing = true;
          }, timing);
        } else {
          clearInterval(this.scannerDrawer);
          if (this.scannerVideo) {
            this.scannerVideo.play();
          }
          this.scannerDrawer = setInterval(this.captureVideoToCanvas, 1000 / 24);
          this.scannerProcessing = true;
        }
      }
    },
    pauseScanner(timing) {
      console.log('#QrCodeScanner pauseScanner', { timing });

      if (timing) {
        setTimeout(() => {
          clearInterval(this.scannerDrawer);
          if (this.scannerVideo) {
            this.scannerVideo.pause();
          }
          this.scannerProcessing = false;
        }, timing);
      } else {
        clearInterval(this.scannerDrawer);
        if (this.scannerVideo) {
          this.scannerVideo.pause();
        }
        this.scannerProcessing = false;
      }
    },
    stopScanner() {
      console.log('#QrCodeScanner stopScanner', {  });

      try {
        this.scannerVideo.srcObject.getVideoTracks()[0].stop();
        this.scannerVideo.srcObject = null;
        clearInterval(this.scannerDrawer);
      } catch (e) {}
    },
    captureVideoToCanvas() {
      console.log('#QrCodeScanner captureVideoToCanvas', {  });

      var canvasBound = this.scannerCanvas.getBoundingClientRect();
      this.scannerCanvas.width = canvasBound.width;
      this.scannerCanvas.height = canvasBound.height;
      this.scannerCanvasContext.drawImage(
        this.$refs.previewVideo,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? 0 : this.scannerVideo.videoWidth / 2 - this.scannerVideo.videoHeight / 2,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? this.scannerVideo.videoHeight / 2 - this.scannerVideo.videoWidth / 2 : 0,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? this.scannerVideo.videoWidth : this.scannerVideo.videoHeight,
        this.scannerVideo.videoHeight > this.scannerVideo.videoWidth ? this.scannerVideo.videoWidth : this.scannerVideo.videoHeight,
        0,
        0,
        canvasBound.width,
        canvasBound.height
      );
      var imageData = this.scannerCanvasContext.getImageData(0, 0, canvasBound.width, canvasBound.height);
      var code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        if (code.data) {
          this.detectQrCode(code.data);
        }
      }
    },
    detectQrCode(query) {
      console.log('#QrCodeScanner detectQrCode', { query });

      try {
        this.detected = true;
        this.$store.dispatch('qrCodeScanner/scan', {
          query: query
        });

        if (this.scannerMode === "web") {
          this.pauseScanner();
          this.closeScanner();
        }
        else {
          this.wni.execute('wnScannerClose', {});
        }
      } catch (e) {
        this.detected = false;

        this.$store.dispatch('qrCodeScanner/error', {
          error: e
        });
      }
    }
  },

  components: {
    Lottie
  }
};
</script>

<style scoped lang="scss">
.qr-code-scanner {
  @include fsf;
  @include container('& > .qr-code-scanner__container', $max-content-width);
  overscroll-behavior: contain;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  z-index: 1800;

  &__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: unit(56);
    background: $color-white;
    text-align: center;
    padding: unit(6) unit(60);
    font-size: unit(14);
    font-weight: 500;
    line-height: 1.43;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1700;

    .header-btn {
      position: absolute;
      width: unit(56);
      height: unit(56);
      top: 0;
      left: 0;

      img {
        margin: auto;
      }
    }
  }

  &__frame {
    @include shadow-box;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;

    &.processing {
      &::before {
        content: '';
        @include fsa;
        width: 20rem;
        height: 20rem;
        background-color: transparent;
        z-index: 2;
      }
    }
  }

  &__video,
  &__loading {
    @include fsa;
    z-index: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__canvas {
    @include fsa;
    height: 100%;
    width: 100%;
    transform: scaleX(-1);
    z-index: 1;

    &.is-camera {
      transform: scaleX(1);
    }
  }

  &__explanatory {
    font-size: unit(14);
    line-height: 1.5;
    color: #a9afb3;
    text-align: center;
    padding: unit(24) 0;
  }

  &__guide {
    @include fsa;
    width: unit(240);
    height: unit(240);
    z-index: 2;

    .guide-item {
      position: absolute;
      width: 2 * $unit;
      height: 2 * $unit;
      border-top: 4px solid;
      border-left: 4px solid;
      border-color: $color-white;

      &.guide-top-left {
        top: 0;
        left: 0;
        transform: rotateZ(0);
      }

      &.guide-top-right {
        top: 0;
        right: 0;
        transform: rotateZ(90deg);
      }

      &.guide-bottom-right {
        bottom: 0;
        right: 0;
        transform: rotateZ(180deg);
      }

      &.guide-bottom-left {
        bottom: 0;
        left: 0;
        transform: rotateZ(270deg);
      }

      &--detected {
        border-color: $color-secondary;

        .guide-text {
          color: $color-secondary;
        }
      }
    }

    .guide-text {
      @include fsa;
      width: 8 * $unit;
      height: 3 * $unit;
      font-size: $font-size-small;
      font-weight: bold;
      line-height: 1.5 * $unit;
      color: $color-white;
      text-align: center;
    }
  }
}
</style>
