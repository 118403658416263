<template>
  <div class="event host-attraction" v-if="visible">
    <router-link :to="{ name: 'HostLanding' }">
      <div class="container">
        <!-- <p class="event__text">럭스테이와 함께할 <strong>호스트</strong>를 찾고 있어요!</p>
        <p class="event__link">
          신청하러 가기 &nbsp;
          <img src="@/assets/icons/svg/arrow-right-white.svg" />
        </p> -->
        <span class="event__text">럭스테이 <strong>호스트</strong>를 찾고 있어요!</span>
        <span class="event__link">
          신청하러 가기 &nbsp;
          <img src="@/assets/icons/svg/arrow-right-white.svg" />
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    visible() {
      return !this.wni.isNative;
    }
  }

  // mounted() {
  //   if (this.visible) {
  //     this.$emit('mounted');
  //   }
  // },

  // destroyed() {
  //   this.$emit('destroyed');
  // },
};
</script>

<style lang="scss" scoped>
.event {
  padding: 20px 20px 20px 60px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__text {
    font-size: 14px;
    color: $color-white;
    // flex: 1;

    strong {
      font-weight: bold;
      color: #faff00;
    }
  }

  &__link {
    margin-right: 0;
    color: $color-white;
    font-size: 14px;
    // flex: 0 0 120px;

    img {
      display: inline-block;
      vertical-align: middle;
      bottom: 1px;
      width: 24px;
    }
  }

  @media screen and (max-width: $site-width-h) {
    .container {
      // flex-direction: column;
      // height: 48px;
    }

    &__text {
      font-size: 14px;
    }

    &__link {
      text-align: right;
      flex: 0 0 auto;
      font-size: 12px;

      img {
        width: 18px;
      }
    }
  }

  @media screen and (max-width: $site-width-h) {
    .container {
      // flex-direction: column;
      // height: 48px;
    }

    &__text {
      font-size: 12px;
    }

    &__link {
      text-align: right;
      flex: 0 0 auto;
      font-size: 12px;

      img {
        width: 16px;
      }
    }
  }
}
</style>
