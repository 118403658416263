<template>
  <div class="dialog-container">
    <transition-group tag="ul" name="dialog">
      <template v-for="( item, i ) in items">
        <dialog-item :key="i" :data="item" />
      </template>
    </transition-group>
  </div>
</template>

<script>
import DialogItem from "./Item";
export default {
  components: {
    DialogItem,
  },
  computed: {
    items(){
      return this.$store.getters[ "dialog/GET_ITEMS" ];
    }
  }
}
</script>
