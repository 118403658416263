<template>
  <!-- <div class="site-events" :class="{ mounted: mounted, openned: openned }"> -->
  <div class="site-events" :class="{ openned: openned }">
    <div class="container">
      <!-- <component :is="currentComponent" class="component" @mounted="onMounted" @destroyed="onDestroyed"></component> -->
      <component :is="currentComponent" class="component"></component>
    </div>

    <button type="button" :class="{ btn: native === false, no: native === true }" @click="onClickEventClose">
      <img src="/img/icon/header-close-white.svg" />
    </button>
  </div>
</template>

<script>
import HostAttractionEvent from '@/components/resources/event/HostAttractionEvent.vue';

export default {
  data() {
    return {
      openned: false,
      native: false
    };
  },

  computed: {
    currentComponent() {
      return HostAttractionEvent;
    },

    eventShown() {
      return this.$store.state.eventShown;
    }
  },

  mounted() {
    if (!this.eventShown) {
      setTimeout(() => {
        this.$store.commit('TURN_EVENT_SHOWN', { mode: true });
        this.openned = true;
      }, 1000);
    }
    if (this.wni.isNative) {
      this.native = true;
    } else {
      this.native = false;
    }
  },

  methods: {
    onClickEventClose() {
      this.openned = false;
    }
  },

  components: {
    HostAttractionEvent
  }
};
</script>

<style lang="scss" scoped>
.site-events {
  width: 100%;
  z-index: 1700;
  line-height: unset;
  height: auto;
  max-height: 0;
  transition: all 0.25s ease-out;
  background-color: #0091ff;

  & > .container {
    background: #333;
    transform: translateY(-100%);
    will-change: transform;
    transition: transform 0.25s ease-in;
  }

  .btn {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 64px;
    padding: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .no {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 64px;
    height: 64px;
    padding: 20px;
    cursor: pointer;

    img {
      display: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.openned {
    max-height: 100px;

    > .container {
      transform: translateY(0);
    }

    .btn {
      display: block;
    }
  }
}
</style>
