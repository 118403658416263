<template>
  <div id="language-form">
    <i class="fa fa-times pointer" @click="close"/>
    <div class="header">
      <img src="/img/icon-global.png"> <span>Select your language</span>
    </div>
    <div class="body">
      <div class="language-select">
        <select @click="gaTracker" id="language-select-form" v-model="lang">
          <option value="ko" :selected="lang == 'ko'">Korean</option>
          <option value="en" :selected="lang == 'en'">English(US)</option>
          <option value="cn" >中文(簡体)</option>
          <option value="hk" >中文(繁體)</option>
          <option value="jp" >日本語</option>
          <option value="vn" >Tiếng Việt</option>
        </select>
        <span>
        {{ lang == "ko" || lang == "kr" ? "Korean" : "" }}
        {{ lang == "en" ? "English(US)" : "" }}
        {{ lang == "cn" ? "中文(簡体)" : "" }}
        {{ lang == "hk" ? "中文(繁體)" : "" }}
        {{ lang == "jp" ? "日本語" : "" }}
        {{ lang == "vn" ? "Tiếng Việt" : "" }}
        </span>
        <i class="fa fa-angle-down"></i>
      </div>
      <button class="button-save" @click="shiftLanguage"><span>Save</span></button>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import utils from '@/utils'

export default {
  data(){
    return {
      lang: config.userLocale.indexOf('ko') !== -1 ? 'ko' : 'en',
    }
  },
  watch:{
    lang(value, oldValue){
      this.gaTracker(value)
    }
  },
  methods: {
    shiftLanguage(){
      const languageSet = {
				'ko' : 'ko',
				'en' : 'En',
				'hk' : 'ch_traditional',
				'cn' : 'ch_Simplified Chinese',
				'jp' : 'jp',
				'vn' : 'vietnamese'
			}

      this.$analytics.tracking.call(this, 'home', 'click', 'home_top_language_save');
      this.$store.dispatch( "auth/edit", { user_language: this.lang }).then( res => {
        this.$analytics.setLanguage(this.lang);
        localStorage.setItem( "lugstay_language", this.lang );
        window.location = utils.removeURLParameter(window.location.href, 'language')
      }).catch( err => {
        this.$analytics.setLanguage(this.lang);
        localStorage.setItem( "lugstay_language", this.lang );
        window.location = utils.removeURLParameter(window.location.href, 'language')
      });
    },
    close() {
      localStorage.setItem( "lugstay_language", this.lang );
      window.location = utils.removeURLParameter(window.location.href, 'language')
      // this.$parent.isSetLanguage = true;
    },
    gaTracker( param ){
      if(typeof param == 'object') this.$analytics.tracking.call(this, 'home', 'click', 'home_top_language');
      else if(param == "cn"){
        this.$analytics.tracking.call(this, 'home', 'click', 'home_top_language_cn_simplified');
      }else if(param == "hk"){
        this.$analytics.tracking.call(this, 'home', 'click', 'home_top_language_cn_traditional');
      }else{
        this.$analytics.tracking.call(this, 'home', 'click', 'home_top_language_'+param);
      }
    }
  },
}
</script>
