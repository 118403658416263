<template>
  <li class="modal-item" :class="{ small: data.size == 'small', big: data.size == 'big' }">
    <div class="container">
      <div class="close" @click="removeItem" v-if="data.hideClose === false">×</div>
      <component
        v-if="data.component"
        :is="data.component"
        :class="data.class"
        :payload="data.payload"
        :hide-close="data.hideClose"
        :redirect="data.redirect"
        :mode="data.mode"
        @close="removeItem"
      />
    </div>
  </li>
</template>

<script>
export default {
  props: ['data'],
  mounted() {
    // console.log(this.data);

    this.$watch('$route', (a, b) => {
      this.removeItem();
    });
    this.$el.addEventListener('mousedown', (e) => {
      if (this.$store.state.config.isTouchDevice || e.which == 1) {
        if (e.target == this.$el) {
          this.removeItem();
        }
      }
    });
  },
  methods: {
    removeItem() {
      if (typeof this.data.onClose === 'function') {
        this.data.onClose(this);
      }

      this.$store.commit('modal/REMOVE_ITEM', this.data);
    }
  }
};
</script>
