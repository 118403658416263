<template>
  <div class="toast-container">
    <toast-item v-for="(item, i) in items" :key="i + '-' + item.status" :data="item"></toast-item>
  </div>
</template>

<script>
import toastItem from './Item';
export default {
  components: {
    toastItem,
  },
  computed: {
    items() {
      return this.$store.getters['toast/GET_ITEMS'];
    },
  },
};
</script>
